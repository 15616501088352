/* 导入element-ui样式
*/
import 'element-ui/lib/theme-chalk/index.css'

import Vue from 'vue'
// import VueMathjax from 'vue-mathjax'
import App from './App.vue'
import VueMatomo from 'vue-matomo'
import router from './router'
import store from './store'
import './assets/app.css'
import './assets/font.css'
import './assets/MathJax.js'

/* element-ui所有组件
*/
import ElementUI from 'element-ui'
Vue.use(ElementUI)
// Vue.use(VueMathjax)
Vue.use(VueMatomo, {
  host: 'https://matomo.kokomi0728.eu.org/',
  siteId: 1
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
