<template>
  <div id="app">
    <el-container>
      <el-header>
        <div class="theme">
          <div class="themefont">
            LLMsParks
          </div>
        </div>
        <el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" @select="handleSelect" :router="true" background-color="#F8F9FA">
          <el-menu-item index="/introduction">Introduction</el-menu-item>
          <el-menu-item index="/">Leaderboard</el-menu-item>
          <el-submenu index="3">
            <template slot="title">Games</template>
            <el-menu-item index="/whoisspy">Who Is Spy</el-menu-item>
            <el-menu-item index="/prisonersdilemma">The Prisoner's Dilemma</el-menu-item>
            <el-menu-item index="/trustgame">The Trust Game</el-menu-item>
            <el-menu-item index="/nim">The Nim Game</el-menu-item>
            <el-menu-item index="/dictatorgame">The Dictator Game</el-menu-item>
          </el-submenu>
          <!-- <el-menu-item index="3">Model Arena</el-menu-item> -->
          <el-menu-item index="/submit">Submit</el-menu-item>
        </el-menu>
      </el-header>
      <el-main><router-view></router-view></el-main>
    </el-container>
  </div>
</template>

<script>
export default {

}
</script>

<style>
/* 稍后删除 */
.el-header,
.el-footer {
  /* background-color: #B3C0D1; */
  color: #333;
  text-align: center;
  line-height: 20%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

html,
body,
.el-container {
  height: 100vh;
  background-color: #F8F9FA;
}

.el-card__body, .el-main{
  padding: 0;
  padding-bottom: 1.5rem;
  display: flex;
  align-content: center;
  justify-content: center;
  height: 80%;
}

.theme {
  width: 20%;
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
}
.themefont{
  font-family: 'Comic Sans MS';
  font-weight: 600;
  font-size: 4vh;
}

.el-menu{
  margin-right: 3rem;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}
</style>